import React from "react";
import { graphql, StaticQuery, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import Layout from "../components/layout";
import SEO from "../components/seo";
import "../utils/normalize.css";
import "../utils/css/screen.css";
import { LANGUAGE, useStore } from "../context/store";
import styled from "styled-components";
import { Title } from "../components/Title";

const HeadshotContainer = styled.div`
  height: 300px;
  width: 300px;
`;
const HeadshotWrapper = styled.div`
  border-radius: 50%;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  margin-bottom: 4px;
`;

const AboutMeContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 5%;
  flex: 0 1 50%;
`;

const Name = styled.div`
  font-family: "Cormorant Garamond";
  color: #3b3638;
  font-size: 21px;
  font-weight: 600;
`;

const AboutMeTextContainer = styled.div`
  margin-top: 72px;
  font-weight: 500;

  @media (max-width: 950px) {
    margin: 73px 8% 0;
  }
`;

const AboutMeText = styled.div`
  font-family: "Cormorant Garamond";
  color: #3b3638;
  margin-bottom: 24px;
`;

const Divider = styled.div`
  background: #f9f7f7;
  height: 800px;
  width: 2px;
  @media (max-width: 950px) {
    width: 80%;
    height: 1px;
    margin: 40px 0;
  }
`;

const WholeWrapper = styled.div`
  display: flex;
  background-color: #e7bdb3;
  padding: 20vh 2vw;
  justify-content: center;
  align-items: flex-start;

  @media (max-width: 950px) {
    flex-direction: column;
    align-items: center;
  }
`;

const AboutPage = ({ data }, location) => {
  const { language, translations } = useStore();
  const isSpanish = language === LANGUAGE.es;

  const { site, juanHeadshot, rocioHeadshot } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
        juanHeadshot: file(relativePath: { eq: "IMG_6008.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1360) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        rocioHeadshot: file(relativePath: { eq: "IMG_6086.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1360) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  );

  return (
    <Layout title={site.siteMetadata.title}>
      <SEO
        title={isSpanish ? "Sobre Nosotros" : "About"}
        keywords={[`blog`, `gatsby`, `javascript`, `react`]}
      />

      <article className="post-content page-template no-image">
        <div className="post-content-body">
          <Title title={translations.meet_out_team} />

          <div style={{ marginLeft: 16, marginRight: 16 }}>
            <figure
              style={{
                width: "100vw",
              }}
              className="kg-card kg-image-card kg-width-full"
            >
              <WholeWrapper>
                <AboutMeContainer>
                  <HeadshotContainer>
                    <HeadshotWrapper>
                      <Img fluid={rocioHeadshot.childImageSharp.fluid} />
                    </HeadshotWrapper>
                    <Name>Rocio Centeno</Name>
                  </HeadshotContainer>
                  {isSpanish ? (
                    <AboutMeTextContainer>
                      <AboutMeText>
                        Llegue a Estados Unidos hace 26 años con planes e ideas
                        completamente diferentes a lo que soy el día de hoy. Por
                        diferentes razones, los planes que tenia no se
                        realizaron como yo esperaba.
                      </AboutMeText>
                      <AboutMeText>
                        Siempre me ha gustado trabajar, aprender y ayudar a
                        quien puedo. Tuve la oportunidad de trabajar como
                        asistente legal durante 16 años, y durante ese tiempo
                        confirme que no hay mejor satisfacción que apoyar y
                        ayudar a los demas. Como en muchos casos, hubo un
                        momento en que senti que mi etapa en ese trabajo había
                        concluido, y empeze a cuestionarme que debería de hacer
                        sintiéndome un poco perdida. Soy una mujer de mucha fe y
                        Dios ha puesto en mi camino personas increíbles que me
                        han alentado mucho a ver hacia adelante como mi hoy
                        socia, a la que agradezco enormemente el empujón para
                        llegar hasta aquí, a mi hijo Danny por siempre estar
                        presente en todo momento, a mi hija Amy por siempre
                        creer en mi, y en esto que empezó solo como una idea!!
                      </AboutMeText>
                      <AboutMeText>
                        Hoy estoy aquí con todas las ganas de servir y ayudar a
                        nuestra comunidad. En este campo tengo la bendición de
                        decir que me pagan por hacer lo que me gusta!
                      </AboutMeText>
                    </AboutMeTextContainer>
                  ) : (
                    <AboutMeTextContainer>
                      <AboutMeText>
                        I came to the United States 26 years ago with completely
                        different ideas and plans of what I am today. For
                        different reasons, the plans that I had did not turn out
                        the way that I expected.
                      </AboutMeText>
                      <AboutMeText>
                        I have always liked to work, learn, and help whoever I
                        can. I had the opportunity to work as a legal assistant
                        for 16 years, and during that time I confirmed that
                        there is no greater satisfaction than to supporting and
                        helping others. Like in many cases, there was a moment
                        that I felt like my time at my job had ended, and I
                        started to question what I should do and feeling a bit
                        lost. I am a woman of faith, and God had placed
                        incredible people that have helped me look forward like
                        my now business partner to whom I greatly appreciate for
                        pushing me to get here, my son Danny for always being
                        present, my daughter Amy who has always believed in me.
                        I am so grateful!
                      </AboutMeText>
                      <AboutMeText>
                        Today I am here, with all the desire to serve and help
                        my community. In this field I am blessed to say that I
                        get paid to what I love!
                      </AboutMeText>
                    </AboutMeTextContainer>
                  )}
                </AboutMeContainer>
                <Divider />
                <AboutMeContainer>
                  <HeadshotContainer>
                    <HeadshotWrapper>
                      <Img fluid={juanHeadshot.childImageSharp.fluid} />
                    </HeadshotWrapper>
                    <Name>Juana Servin</Name>
                  </HeadshotContainer>
                  {isSpanish ? (
                    <AboutMeTextContainer>
                      <AboutMeText>
                        Nací en México y mis padres me trajeron a los Estados
                        Unidos cuando solo tenía 8 meses. Creciendo con padres
                        que no sabían nada de Ingles yo siempre tenia que
                        traducir y llenar todos sus documentos legales. Después,
                        no solo era para mis padres, sino que también para mis
                        familiares y amigos de la familia. Aprendí a amar ayudar
                        a la gente entonces decidí buscar un trabajo en servicio
                        al cliente y después en el campo legal.
                      </AboutMeText>
                      <AboutMeText>
                        Soy una mamá soltera de una hermosa niña quien es mi
                        orgullo y alegría. Mi hija Ayleen, y el amor a ayudar a
                        los demás, fueron las razones por las que decidí abrir
                        este negocio.
                      </AboutMeText>
                      <AboutMeText>
                        ¡Estoy muy orgullosa de decir que voy a poder ayudar a
                        las personas de mi comunidad!
                      </AboutMeText>
                    </AboutMeTextContainer>
                  ) : (
                    <AboutMeTextContainer>
                      <AboutMeText>
                        I was born in Mexico and brought to the United States as
                        a baby. Growing up with parents that did not know any
                        English I always had to translate and fill out all their
                        legal documents. After a while it was not just for my
                        parents but for family and family friends. I learned to
                        love helping them out, so I decided to look for a job in
                        customer service and then legal field.
                      </AboutMeText>
                      <AboutMeText>
                        I am a single mother of a beautiful little girl who is
                        my pride and joy. My daughter Ayleen and the love that I
                        have for helping others are the reasons if why I decided
                        to open this business.
                      </AboutMeText>
                      <AboutMeText>
                        I am so proud to say that I am going to be able to help
                        people in my community!
                      </AboutMeText>
                    </AboutMeTextContainer>
                  )}
                </AboutMeContainer>
              </WholeWrapper>
            </figure>
          </div>
        </div>
      </article>
    </Layout>
  );
};

export default AboutPage;
